// src/hooks/useRefreshToken.js
import { useCallback } from 'react';
import initializeAxios from '../axiosInstance';

const useRefreshToken = () => {
  const refreshToken = useCallback(async () => {
    try {
      await initializeAxios.refreshToken();
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  }, []);

  return refreshToken;
};

export default useRefreshToken;
